export default {
  methods: {
    toastSuccess (message, title = undefined) {
      if (title === undefined) {
        title = 'success'
      }

      this.toast(message, { title, variant: 'success' })
    },

    toastWarning (message, title = undefined) {
      if (title === undefined) {
        title = 'warning'
      }

      this.toast(message, { title, variant: 'warning' })
    },

    toastDanger (message, title = undefined) {
      if (title === undefined) {
        title = 'error'
      }

      this.toast(message, { title, variant: 'danger', 'noAutoHide': true })
    },

    toast (msg, opt = { variant: 'info' }) {
      this.$root.$bvToast.toast(msg, opt)
    },

    toastErrorHandler (opt) {
      if (typeof opt === 'string') {
        opt = { title: opt }
      }

      const { title } = opt

      return (err = {}) => {
        if (err.message && err.message.startsWith('notification')) {
          err.message = 'notification:' + err.message.substring('notification.'.length)
        }
        /* eslint-disable no-console */
        console.error(err)
        const msg = err.message
        this.toastDanger(msg, title)
      }
    },
  },
}
