const configurationList = [
  //! Required
  {
    key: 'API_ORIGIN_URL',
    def: 'Smart ERM API URL to use.',
    required: true,
  },
  {
    key: 'API_AUTH_CLIENT_ID',
    def: 'Client ID to use to establish connection with Smart ERM API.',
    required: true,
  },
  {
    key: 'API_AUTH_SECRET',
    def: 'Client Secret to use to establish connection with Smart ERM API.',
    required: true,
  },
  {
    key: 'API_NAMESPACE_ID',
    def: 'Namespace ID to use when uploading data using the API.',
    required: true,
  },
  {
    key: 'API_CASE_MODULE_ID',
    def: 'Module ID of the Cases to use when uploading data using the API.',
    required: true,
  },
  {
    key: 'API_ANSWER_MODULE_ID',
    def: `Module ID where to store the answer's JSON.`,
    required: true,
  },
  {
    key: 'API_SURVEY_MODULE_ID',
    def: `Module ID where to store the survey's JSON.`,
    required: true,
  },
  {
    key: 'API_MESSAGE_MODULE_ID',
    def: `Module ID of the Messages.`,
    required: true,
  },
  //! NOT Required
  {
    key: 'FOOTER_JSON_CONFIG',
    def: `Footer JSON configuration structured as:
{
  default: {
    "label": "link",
    [...]
  },
  fr: {
    "label": "link",
    [...]
  },
  [...]
}`,
    required: false,
    json: true,
  },
  {
    key: 'APP_NAME',
    def: 'Name of the application to display (default to "Whistleblowing").',
    required: false,
  },
  {
    key: 'LOGO_URL',
    def: 'URL of the logo to display at the top (default to Smart Global Governance© logo).',
    required: false,
  },
  {
    key: 'LOGO_HEIGHT',
    def: 'Height of the logo to display at the top (default to 140px).',
    required: false,
  },
  {
    key: 'CUSTOM_CASE_DETAIL_CREATE_AT_LABEL',
    def: 'Custom label to display for case detail created at.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_CASE_DETAIL_ID_LABEL',
    def: 'Custom label to display for case detail ID.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_CASE_DETAIL_LABEL',
    def: 'Custom label to display for case detail.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_CASE_DETAIL_STATUS_LABEL',
    def: 'Custom label to display for case detail status.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_CASE_TAB_CASE_SUBMITTED_DESCRIPTION',
    def: 'Custom description to display when a case is submitted.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_CASE_TAB_CASE_SUBMITTED_LABEL',
    def: 'Custom label to display when a case is submitted.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_CASE_TAB_LABEL',
    def: 'Custom label to display in the tab "View a case" when a case is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_MESSAGE_TAB_INTRODUCTION',
    def: 'Custom introduction to display when a message tab is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_MESSAGE_TAB_LABEL',
    def: 'Custom label to display in the tab "Leave a message" when a case is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_MESSAGE_TAB_SEND_MESSAGE',
    def: 'Custom label displayed above the message input field when a message tab is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_MESSAGE_TAB_USER_ONLY',
    def: 'Custom message to display when an admin user access the message tab (message for whistleblowers only).',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_WELCOME_FOLLOW_CASE_LABEL',
    def: 'Custom follow case button label to display when the welcome page is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_WELCOME_FOLLOW_CASE_TOKEN_LABEL',
    def: 'Custom tokenID label to display when the welcome page is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_WELCOME_INTRODUCTION',
    def: 'Custom introduction header to display when the welcome page is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_WELCOME_INTRODUCTION_FOOTER',
    def: 'Custom introduction footer to display when the welcome page is opened.',
    required: false,
    json: true,
  },
  {
    key: 'CUSTOM_WELCOME_LABEL',
    def: 'Custom label to display when the welcome page is opened.',
    required: false,
    json: true,
    },
  {
    key: 'CUSTOM_WELCOME_SUBMIT_CASE',
    def: 'Custom submit case button label to display when the welcome page is opened.',
    required: false,
    json: true,
    },
]

const requiredConfigurationList = configurationList.filter(({ required }) => required)

export {
  configurationList,
  requiredConfigurationList,
}
