
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faDownload,
  faHouse,
  faTrash,
  faUndo,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCopy,
  faEdit,
  faFileAlt,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faCheck,
  faDownload,
  faHouse,
  faTrash,
  faUndo,
  faUserLock,

  faCopy,
  faEdit,
  faFileAlt,
)
