import { defineStore } from 'pinia'
import { CaseDataService } from '@/services'

export const useCaseStore = defineStore('case', {
  state: () => ({
    loading: false,
    pending: false,
    current: null,
  }),

  actions: {
    updateCurrent (data) {
      this.current = Object.freeze(data)
    },

    async findByHash ({ hash } = {}) {
      this.pending = true
      return CaseDataService.get(hash)
        .then(({ data }) => {
          if (Object.keys(data).length) this.updateCurrent(data)

          return this.current
        }).finally(() => {
          this.pending = false
        })
    },

    async create ({ json } = {}) {
      this.pending = true
      return CaseDataService.create({ answerJson: json })
        .then(({ data }) => {
          const [caseRecord] = data

          return caseRecord
        }).finally(() => {
          this.pending = false
        })
    },

    async reset () {
      this.current = null
    },
  },
})
