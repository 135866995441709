import { defineStore } from 'pinia'
import { ConfigurationDataService } from '@/services'

export const useConfigurationStore = defineStore('configuration', {
  state: () => ({
    loading: false,
    pending: false,
    set: [],
  }),

  getters: {
    getByKey (state) {
      return (configKey) => state.set.find(({ key }) => configKey === key)
    },

    getByKeyWithLng(state) {
      return (configKey, lng) => {
        const customValue = state.getByKey(configKey)
        if (customValue && customValue.value) {
          const jsonLabels = JSON.parse(customValue.value)
          return jsonLabels[lng] || ''
        }
        return ''
      }
    },

    getByID (state) {
      return (ID) => state.set.find(({ id }) => ID === id)
    },
  },

  actions: {
    updateSet (data) {
      const set = data.map(i => Object.freeze(i))

      if (this.set.length === 0) {
        this.set = set
        return
      }

      set.forEach(newItem => {
        const oldIndex = this.set.findIndex(({ key }) => key === newItem.key)
        if (oldIndex > -1) {
          this.set.splice(oldIndex, 1, newItem)
        } else {
          this.set.push(newItem)
        }
      })
    },

    async load ({ clear = false, force = false } = {}) {
      if (clear) {
        this.pending = false
        this.set.splice(0)
      }

      if (!force && this.set.length > 1) {
        // When there's forced load, make sure we have more than 1 item in the set
        // in the scenario when user came to detail page first and has one item loaded
        // > 0 would not be sufficient.
        return new Promise((resolve) => resolve(this.set))
      }

      this.loading = true
      this.pending = true
      return ConfigurationDataService.getAll().then(({ data }) => {
        if (data && data.length > 0) {
          this.updateSet(data)
        }

        return this.set
      }).finally(() => {
        this.loading = false
        this.pending = false
      })
    },

    async findByKey ({ key, force = false } = {}) {
      if (!force) {
        const oldItem = this.getByKey(key)
        if (oldItem) {
          return new Promise((resolve) => resolve(oldItem))
        }
      }

      this.pending = true
      return ConfigurationDataService.get(key).then(({ data }) => {
        this.updateSet([data])

        return data
      }).finally(() => {
        this.pending = false
      })
    },

    async create ({ key, value = '' } = {}) {
      this.pending = true
      return ConfigurationDataService.create({ key, value }).then(({ data }) => {
        this.updateSet([data])

        return data
      }).finally(() => {
        this.pending = false
      })
    },

    async update ({ key, value = '' } = {}) {
      this.pending = true
      return ConfigurationDataService.update(key, { value }).then(({ data }) => {
        this.updateSet([data])

        return data
      }).finally(() => {
        this.pending = false
      })
    },

    async delete ({ key } = {}) {
      this.pending = true
      return ConfigurationDataService.delete(key).then(({ data }) => {
        ([data] || []).forEach(removedItem => {
          const i = this.set.findIndex(({ key }) => key === removedItem.key)
          if (i > -1) {
            this.set.splice(i, 1)
          }
        })

        this.load({ clear: true })
        return true
      }).finally(() => {
        this.pending = false
      })
    },
  },
})
