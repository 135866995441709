import { defineStore } from 'pinia'
import { QuestionDataService } from '@/services'

export const useQuestionStore = defineStore('question', {
  state: () => ({
    loading: false,
    pending: false,
    latest: null,
  }),

  actions: {
    updateLatest (data) {
      this.latest = Object.freeze(data)
    },

    async load ({ force = false } = {}) {
      if (!force && this.latest.recordID) {
        return new Promise((resolve) => resolve(this.latest))
      }

      this.loading = true
      this.pending = true
      return QuestionDataService.getLatest()
        .then(({ data }) => {
          this.updateLatest(data)

          return this.latest
        }).finally(() => {
          this.loading = false
          this.pending = false
        })
    },
  },
})
