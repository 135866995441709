import http from '../http-common'

class ConfigurationDataService {
  create(data) {
    return http.post('/configurations', data)
  }
  getAll() {
    return http.get('/configurations')
  }
  get(key) {
    return http.get(`/configurations/${key}`)
  }
  update(key, data) {
    return http.put(`/configurations/${key}`, data)
  }
  delete(key) {
    return http.delete(`/configurations/${key}`)
  }
}

export default new ConfigurationDataService()