<template>
  <b-container class="mt-2">
    <header
      class="text-center"
    >
      <b-btn
        variant="primary"
        @click="toggleForm()"
      >
        <span v-if="!showForm">Create</span>
        <span v-else>Close</span>
      </b-btn>
    </header>

    <main class="mt-3">
      <b-form
        v-if="showForm"
        @submit.stop.prevent="onSubmit()"
      >
        <b-form-group
          v-show="form.id"
          label="ID :"
          label-for="id-input"
          label-cols="2"
        >
          <b-form-input
            id="id-input"
            v-model="form.id"
            type="number"
            disabled
          />
        </b-form-group>

        <b-form-group
          label="Name :"
          label-for="key-input"
          label-cols="2"
          description="Configuration's name to add."
        >
          <b-form-input
            id="key-input"
            v-model="form.key"
            placeholder="Enter the name"
            type="text"
            required
            :disabled="form.id > 0"
            @input="convertKey()"
          />
        </b-form-group>

        <b-form-group
          label="Value :"
          label-for="value-input"
          label-cols="2"
          description="Configuration's value to add."
        >
          <b-textarea
            id="value-input"
            v-model="form.value"
            placeholder="Enter the value"
          />
        </b-form-group>

        <b-btn
          type="submit"
          variant="success"
        >
          Save
        </b-btn>
      </b-form>

      <div id="configuration-list" class="my-3">
        <p>
          <b>List of valid configuration KEY that you should set:</b>
          <b-btn
            @click="showList = !showList"
            class="ml-2"
            variant="info"
            size="sm"
          >
            {{ showList ? 'Hide' : 'Show' }}
          </b-btn>
        </p>
        <ul v-if="showList">
          <li
            v-for="c of configurationList"
            :key="c.id"
            :class="{
              'required': c.required,
            }"
          >
            <span class="configuration-key">{{ c.key }}</span> =
            <span class="configuration-definition break-spaces">{{ c.def }}</span>
          </li>
          <li
            v-if="!configurationList.length"
            class="text-success"
          >
            <b>All existing configuration key are set!</b>
          </li>
        </ul>
      </div>

      <b-table
        id="configurations-table"
        :items="configurationStore.set"
        :fields="tableFields"
        :busy="loading"
        striped
        hover
        fixed
        stacked="xl"
        class="mt-3"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width: colWidth(field.key)
            }"
          >
        </template>

        <template #cell(key)="data">
          <div
            class="overflow-ellipsis"
            :title="data.value"
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(value)="data">
          <div
            class="overflow-ellipsis"
            :title="data.value"
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <font-awesome-icon
              :icon="['far', 'edit']"
              class="text-primary c-pointer"
              title="Update"
              @click="updateConfigForm(data.item.key)"
            />
            <font-awesome-icon
              :icon="['fas', 'trash']"
              class="ml-2 text-danger c-pointer"
              title="Delete"
              @click="deleteConfigForm(data.item.key)"
            />
          </div>
        </template>
      </b-table>
    </main>
  </b-container>
</template>

<script>
import { useConfigurationStore } from '@/stores'
import { mapStores } from 'pinia'
import { configurationList as baseConfigurationList } from '@/lib/configurationList.js'

export default {
  data () {
    return {
      loading: false,
      showForm: false,
      showList: false,
      form: {
        id: 0,
        key: '',
        value: '',
      },
    }
  },

  computed: {
    ...mapStores(useConfigurationStore),

    configurationList () {
      return baseConfigurationList.filter(({ key }) => !this.configurationStore.getByKey(key))
    },

    tableFields () {
      return [
        {
          key: 'key',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'value',
          label: 'Value',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ]
    },
  },

  methods: {
    updateConfigForm (key) {
      const config = this.configurationStore.getByKey(key)

      this.form.id = config.id
      this.form.key = config.key
      this.form.value = config.value

      this.showForm = true
    },

    deleteConfigForm (key) {
      if (confirm(`Êtes-vous sure de vouloir supprimer la clé "${key}"`)) {
        this.loading = true
        this.configurationStore.delete({ key })
          .finally(() => {
            this.configurationStore.load({ clear: true })
            this.resetForm()

            this.loading = false
          })
      }
    },

    onSubmit () {
      this.loading = true
      if (this.form.id) { // Update
        this.configurationStore.update({
          key: this.form.key,
          value: this.form.value,
        }).finally(() => {
          this.configurationStore.load({ clear: true })
          this.resetForm()

          this.loading = false
        })
      } else { // Create
        this.configurationStore.create({
          key: this.form.key,
          value: this.form.value,
        }).finally(() => {
          this.configurationStore.load({ clear: true })
          this.resetForm()

          this.loading = false
        })
      }
    },

    resetForm () {
      this.showForm = false
      this.form.id = 0
      this.form.key = ''
      this.form.value = ''
    },

    toggleForm () {
      if (this.showForm) this.resetForm()
      else this.showForm = true
    },

    convertKey () {
      this.form.key = this.form.key
        .toUpperCase()
        .replaceAll(' ', '_')
    },

    colWidth (key) {
      switch(key) {
        case 'key':
          return '25%'
        case 'value':
          return '60%'
        case 'actions':
          return '15%'
        default:
          return '0'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#configuration-list {
  ul {
    .required .configuration-key::after {
      content: " *";
      color: $danger;
    }
    .configuration-key {
      font-weight: 700;
    }
  }
}

#configurations-table {
  .overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
