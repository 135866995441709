<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-alert
          class="break-spaces"
          variant="dark"
          show
        >
          {{ messageTabIntroduction }}
        </b-alert>
      </b-col>
    </b-row>

    <b-row
      v-if="messageStore.set.length > 0"
      id="messages-box"
    >
      <b-col
        cols="12"
        md="8"
        offset-md="2"
      >
        <b-card
          :class="{
            'user-pov': userPov,
            'admin-pov': !userPov,
          }"
        >
          <b-alert
            v-for="m of messageStore.set"
            :key="m.recordID"
            :class="{
              'ml-auto': rightMessage(m),
              'user-message': !messageIsAdmin(m),
              'admin-message': messageIsAdmin(m),
            }"
            :variant="!rightMessage(m) ? 'warning' : 'primary'"
            show
          >
            <p class="message-content break-spaces">{{ m.values.find(v => v.name === 'comment').value }}</p>
            <div class="message-timestamp">{{ messageCreatedAt(m) }}</div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        cols="12"
        md="8"
        offset-md="2"
      >
        <b-card>
          <b-form
            @submit.stop.prevent="sendMessage()"
          >
            <b-form-group
              :label="messageTabSendMessage"
              label-class="break-spaces"
              :description="isAdmin ? messageTabUserOnly : ''"
            >
              <b-input-group>
                <b-form-input
                  v-model="messageInput"
                  placeholder="Message"
                  :disabled="isAdmin"
                />
                <b-input-group-append>
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="isAdmin"
                  >
                    {{ $t('global.send') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { useMessageStore, useCaseStore, useConfigurationStore } from '@/stores'
import { mapStores } from 'pinia'
import {
  customMessageTabIntroduction,
  customMessageTabSendMessage,
  customMessageTabUserOnly,
} from '@/lib/defaults'

export default {
  name: 'Message',

  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      messageInput: '',
    }
  },

  created () {
    this.$emit('loading', `${this.$t('loadingMessage.fetchingMessages')}...`)
    this.messageStore.load({
      tokenID: this.caseStore.current.recordID,
      force: true,
    }).finally(() => {
      this.$emit('loaded')
    })
  },

  computed: {
    ...mapStores(useMessageStore, useCaseStore, useConfigurationStore),

    userPov () {
      return !this.isAdmin
    },

    messageTabIntroduction () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_MESSAGE_TAB_INTRODUCTION', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_MESSAGE_TAB_INTRODUCTION' }), this.$t('errors.userInput'))
      }
      return i18nText || customMessageTabIntroduction[lng] || customMessageTabIntroduction['default']
    },
    
    messageTabUserOnly () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_MESSAGE_TAB_USER_ONLY', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_MESSAGE_TAB_USER_ONLY' }), this.$t('errors.userInput'))
      }
      return i18nText || customMessageTabUserOnly[lng] || customMessageTabUserOnly['default']
    },

    messageTabSendMessage () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_MESSAGE_TAB_SEND_MESSAGE', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_MESSAGE_TAB_SEND_MESSAGE' }), this.$t('errors.userInput'))
      }
      return i18nText || customMessageTabSendMessage[lng] || customMessageTabSendMessage['default']
    },
  },

  methods: {
    sendMessage () {
      this.$emit('loading', `${this.$t('loadingMessage.sendingMessage')}...`)
      const message = this.messageInput
      this.messageStore.create({
        tokenID: this.caseStore.current.recordID,
        message,
      }).then(() => {
        this.messageInput = ''
      }).finally(() => {
        this.$emit('loaded')
      })
    },

    rightMessage (msg) {
      return (!this.userPov && this.messageIsAdmin(msg)) || (this.userPov && !this.messageIsAdmin(msg))
    },

    messageIsAdmin (msg) {
      return msg.createdBy !== '321155304794390532'
    },

    messageCreatedAt ({ createdAt }) {
      return new Date(createdAt).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute:'2-digit',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#messages-box {
  .user-message,
  .admin-message {
    border-radius: 10px;
    min-height: 4rem;
    padding: 10px;
    position: relative;
    width: 200px;

    .message-content {
      padding: 0;
    }
    .message-timestamp {
      bottom: 5px;
      font-size: 0.65rem;
      position: absolute;
    }
  }

  .user-message {
    &::before,
    &::after {
      content: '';
      position: absolute;
    }
  }
  .admin-message {
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
    }
  }

  .admin-pov .user-message,
  .user-pov .admin-message {
    &::before {
      border-top: 17px solid #fdf6e6;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      left: -17px;
      top: -1px;
    }
    &::after {
      border-top: 15px solid #fdf6e6;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      left: -15px;
      top: 0;
    }
    .message-timestamp {
      left: 10px;
    }
  }
  .admin-pov .admin-message,
  .user-pov .user-message {
    &::before {
      border-bottom: 17px solid #ced6dc;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      bottom: -1px;
      right: -17px;
    }
    &::after {
      border-bottom: 15px solid #ced6dc;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      bottom: 0;
      right: -15px;
    }
    .message-timestamp {
      right: 10px;
    }
  }
}
</style>
