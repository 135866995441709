<template>
  <div>
    <b-row
      v-if="false"
      class="mb-2 text-center"
    >
      <b-col v-if="isAdmin">
        <b-btn
          v-if="showSurveyCreatorAction"
          variant="danger"
          size="lg"
          @click="toggleSurveyCreator"
        >
          <font-awesome-icon
            :icon="['far', 'edit']"
          /> Creation mode
        </b-btn>

        <div
          v-if="showSurveyViewerAction"
        >
          <b-btn
            variant="success"
            size="lg"
            @click="toggleSurveyViewer"
            class="mr-2"
          >
            <font-awesome-icon
              :icon="['far', 'file-alt']"
            /> Display mode
          </b-btn>
        </div>
      </b-col>
    </b-row>

    <case-submitted
      :case-token-i-d="caseTokenID"
    />

    <b-card no-body>
      <div class="survey-js-editor">
        <div
          v-show="surveyMode === 'creator'"
          id="survey-creator-container"
        />
        <surveyvue
          v-show="surveyMode === 'viewer'"
          id="surveyContainer"
          :survey="surveyModel"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import * as SurveyVue from 'survey-vue'
import * as SurveyCreator from 'survey-creator-knockout'
import 'survey-core/survey.i18n'
import 'survey-core/defaultV2.min.css'
import 'survey-core/modern.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import 'survey-creator-core/survey-creator-core.i18n.js'
import showdown from 'showdown'

import { AnswerDataService } from '@/services'
import { useQuestionStore, useCaseStore } from '@/stores'
import { mapStores } from 'pinia'

import CaseSubmitted from '@/components/case/CaseSubmitted'

export default {
  name: 'SurveyJS',

  components: {
    surveyvue: SurveyVue.Survey,
    CaseSubmitted,
  },

  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },

    userCanAnswerSurvey: {
      type: Boolean,
      default: false,
    },

    isViewCaseMode: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      surveyModel: new SurveyVue.Model(),
      surveyMode: 'viewer',

      questionField: {},
      answerField: {},

      localStorageID: 'survey-backup',
      caseTokenID: '',
    }
  },

  computed: {
    ...mapStores(useQuestionStore, useCaseStore),

    surveyLanguage () {
      return this.$i18n.locale
    },

    inEditing () {
      return true
    },

    inCreation () {
      return true
    },

    modeCreator () {
      return this.surveyMode === 'creator'
    },

    modeViewer () {
      return this.surveyMode === 'viewer'
    },

    isConfigured () {
      return true
    },

    userCanEditSurvey () {
      return this.isConfigured && this.isAdmin
    },

    showSurveyCreatorAction () {
      return this.userCanEditSurvey && this.modeViewer
    },

    showSurveyViewerAction () {
      return this.userCanAnswerSurvey && this.modeCreator
    },
  },

  mounted () {
    this.$emit('loading', `${this.$t('loadingMessage.fetchForm')}...`)

    this.questionStore.load({ force: true })
      .then(() => {
        if (this.isViewCaseMode) {
          this.$emit('loading', `${this.$t('loadingMessage.fetchForm')}...`)

          const hash = this.caseStore.current.recordID
          AnswerDataService.get(hash)
            .then(({ data: record }) => {
              try {
                this.answerField = JSON.parse(record.values.find(({ name }) => name === 'json_string').value)
                this.initSurveys()
              } catch (e) {
                this.toastDanger(e.message, this.$t('errors.invalidJson', { key: 'question' }))
              }
            }).finally(() => {
              this.$emit('loaded')
            }).catch(() => {
              this.toastDanger(this.$t('errors.answerNotFound'), this.$t('errors.serverIssue'))
            })
        } else {
          this.initSurveys()
        }
      }).finally(() => {
        this.$emit('loaded')
      }).catch(() => {
        this.toastDanger(this.$t('errors.questionNotFound'), this.$t('errors.serverIssue'))
      })
  },

  methods: {
    initSurveyCreator () {
      //! Translations
      SurveyCreator.localization.currentLocale = this.surveyLanguage

      //! Create SurveyCreator instance
      const creatorOptions = {
        showJSONEditorTab: this.isAdmin,
        showPropertyGrid: false,
        showTranslationTab: this.isAdmin,
        showEmbededSurveyTab: false,
        showToolbox: this.inEditing,
        haveCommercialLicense: true,
        isAutoSave: this.inEditing,
        readOnly: !this.inEditing,
      }
      this.surveyCreator = new SurveyCreator.SurveyCreator(creatorOptions)

      //! Init question JSON
      if (this.isConfigured) {
        this.surveyCreator.text = JSON.stringify(this.questionField)
      }

      if (!this.inEditing) {
        //! Remove Toolbar
        this.surveyCreator.toolbarItems.removeAll()
      }

      setTimeout(() => {
        this.surveyCreator.render('survey-creator-container')
      }, 0)
    },

    initSurveyVue () {
      //! Set 'bootstrap' theme style
      SurveyVue
        .StylesManager
        .applyTheme('modern')
      SurveyVue.defaultV2Css.navigationButton = 'btn-success'

      //! Review buttons UI
      // Dynamic panel
      SurveyVue.defaultV2Css.paneldynamic.button = 'btn'
      SurveyVue.defaultV2Css.paneldynamic.buttonAdd = 'btn-primary sv-paneldynamic__add-btn'
      SurveyVue.defaultV2Css.paneldynamic.buttonRemove = 'btn-danger sv_p_remove_btn'
      // Radiogroup
      SurveyVue.defaultV2Css.radiogroup.clearButton = 'sv_q_radiogroup_clear btn btn-info'

      if (this.isConfigured) {
        //! Init question JSON
        this.surveyModel = new SurveyVue.Model(this.questionField)
        //! Init answer JSON
        this.surveyModel.data = this.answerField || {}
        this.surveyModel.locale = this.surveyLanguage
        this.surveyModel.sendResultOnPageNext = true

        //! Customize completed page HTML
        // this.surveyModel.completedHtml = `<h1 class="text-center">Merci d'avoir soumis votre alerte !</h1>`

        //! Recover partial survey save in local storage
        if (!this.isViewCaseMode) this.getSavedAnswerFromLocalStorage()

        //! Set mode to 'display'
        if (!this.inEditing || !this.userCanAnswerSurvey) {
          this.surveyModel.mode = 'display'
        }

        //! Allow markdown in question title
        this.addConverterToSurvey()

        //! Save Answer JSON
        this.handleAnswersOnSurvey()
      }
    },

    getSavedAnswerFromLocalStorage () {
      const storedPartialData = window.localStorage.getItem(this.localStorageID) || null
      if (storedPartialData) {
        const data = JSON.parse(storedPartialData)
        data.pageNo = 'page1'
        this.surveyModel.data = data
        this.answerField = data
        if (data.pageNo) {
          this.surveyModel.currentPageNo = data.pageNo
        }
      }
    },

    addConverterToSurvey () {
      const converter = new showdown.Converter()
      this.surveyModel
        .onTextMarkdown
        .add((_, options) => {
          let str = converter.makeHtml(options.text)
          str = str.substring(3)
          str = str.substring(0, str.length - 4)
          options.html = str;
        })
    },

    handleAnswersOnSurvey () {
      this.surveyModel
        .onValueChanged
        .add((sender) => {
          this.answerField = sender.data
          this.savePartialSurvey(sender)
        })
      this.surveyModel
        .onDynamicPanelItemValueChanged
        .add((sender) => {
          this.answerField = sender.data
          this.savePartialSurvey(sender)
        })
      this.surveyModel
        .onMatrixCellValueChanged
        .add((sender) => {
          this.answerField = sender.data
          this.savePartialSurvey(sender)
        })
      this.surveyModel
        .onPartialSend
        .add((sender) => {
          this.savePartialSurvey(sender)
        })
      this.surveyModel
        .onComplete
        .add((sender) => {
          document.querySelector('#surveyContainer .sv_progress-buttons__container-center').remove()

          this.$emit('loading', `${this.$t('loadingMessage.sendAnswer')}...`)

          this.caseStore.create({ json: JSON.stringify(sender.data) })
            .then((record) => {
              const tokenID = record.values.find(v => v.name === 'id_token').value
              this.caseTokenID = tokenID

              this.clearPartialSurvey()
            }).catch(e => {
              this.toastDanger(e.message, this.$t('errors.newCaseFailed'))
            }).finally(() => {
              this.$emit('loaded')
            })
        })
    },

    initSurveys () {
      this.$emit('loading', `${this.$t('loadingMessage.prepareForm')}...`)
      this.loadQuestion()
      if (this.surveyMode === 'creator') {
        this.initSurveyCreator()
      } else {
        this.initSurveyVue()
      }
      this.$emit('loaded')
    },

    loadQuestion () {
      try {
        const q = this.questionStore.latest || { values: [] }
        const field = q.values.find(v => v.name === 'json_string') || {}
        this.questionField = JSON.parse(field.value || '{}')
      } catch (error) {
        this.toastDanger(error.message, this.$t('errors.invalidJson', { key: 'question' }))
      }
    },

    toggleSurveyCreator () {
      this.surveyMode = 'creator'
      this.initSurveys()
    },

    toggleSurveyViewer () {
      this.surveyMode = 'viewer'
      this.initSurveys()
    },

    savePartialSurvey ({ data, currentPageNo }) {
      data.pageNo = currentPageNo
      window.localStorage.setItem(this.localStorageID, JSON.stringify(data))
    },

    clearPartialSurvey () {
      window.localStorage.removeItem(this.localStorageID)
    },
  },

  watch: {
    surveyLanguage: {
      handler () {
        this.initSurveys()
      },
      immediate: false,
    },
  },
}
</script>

<style lang="scss">
#survey-creator-container {
  .sl-table .svc-logic-operator {
    box-sizing: content-box;
  }
}

#surveyContainer {
  .sd-completedpage {
    background-color: $white;
  }
}
</style>
