<template>
  <footer
    v-if="!isFooterEmpty"
    class="bg-dark p-2"
  >
    <b-container
      v-if="isFooterValid"
    >
      <b-row>
        <b-col
          class="align-self-center"
          cols="6"
          lg="9"
        >
          <b-row
            cols="1"
            cols-md="2"
            cols-lg="4"
          >
            <b-col
              v-for="(elem, i) of footerList"
              :key="i"
            >
              <b-link
                :href="elem.url"
                target="_blank"
                rel="noreferrer"
                class="text-white"
              >
                {{ elem.label }}
              </b-link>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="text-white text-center">
          <span class="icon-logo-container" />
          <p class="copyandtext">&copy; Smart Global Governance</p>
        </b-col>
      </b-row>
    </b-container>

    <div v-else>
      <b-alert
        class="m-0"
        variant="danger"
        show
      >
        {{ $t('alerts.footerInvalid', { appName }) }}
      </b-alert>
    </div>
  </footer>
</template>

<script>
import { useConfigurationStore } from '@/stores'
import { mapStores } from 'pinia'

export default {
  name: 'Footer',

  data () {
    return {
      jsonFooterConfig: {},
    }
  },

  computed: {
    ...mapStores(useConfigurationStore),

    footerConfig () {
      return this.configurationStore.getByKey('FOOTER_JSON_CONFIG')
    },

    footerList () {
      if (this.isFooterValid && !this.isFooterEmpty) {
        let lang = this.$i18n.locale.toLowerCase()
        if (lang === 'en') lang = 'default'

        const result = []
        let config = this.jsonFooterConfig[lang]
        if ((!config || !Object.keys(config).length) && lang !== 'default') {
          lang = 'default'
          config = this.jsonFooterConfig[lang]
        }

        for (const label in config) {
          const url = config[label]

          result.push({
            label,
            url,
          })
        }

        return result
      }
      return []
    },

    isFooterEmpty () {
      return !Object.keys(this.jsonFooterConfig).length
    },

    isFooterValid () {
      return this.isFooterEmpty || (
        this.jsonFooterConfig.default &&
        Object.keys(this.jsonFooterConfig.default).length > 0
      )
    },

    appName () {
      const appName = this.configurationStore.getByKey('APP_NAME')
      return (appName && appName.value) || 'Whistleblowing'
    },
  },

  watch: {
    footerConfig: {
      handler (v) {
        if (v && v.value) {
          try {
            this.jsonFooterConfig = JSON.parse(v.value)
          } catch (error) {
            console.error(error)
            this.toastDanger(this.$t('errors.footerBadJson'), this.$t('errors.configurationIssue'))
            return
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  font-size: 0.8rem;
  max-height: 5rem;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;

  .icon-logo-container {
    display: block;
    background-image: url('../../themes/img/icon-white.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;
    min-height: 3rem;
    width: 3rem;
  }

  .copyandtext {
    font-size: 0.8rem;
  }
}
</style>
