var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"break-spaces",attrs:{"variant":"dark","show":""}},[_vm._v(" "+_vm._s(_vm.messageTabIntroduction)+" ")])],1)],1),(_vm.messageStore.set.length > 0)?_c('b-row',{attrs:{"id":"messages-box"}},[_c('b-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('b-card',{class:{
          'user-pov': _vm.userPov,
          'admin-pov': !_vm.userPov,
        }},_vm._l((_vm.messageStore.set),function(m){return _c('b-alert',{key:m.recordID,class:{
            'ml-auto': _vm.rightMessage(m),
            'user-message': !_vm.messageIsAdmin(m),
            'admin-message': _vm.messageIsAdmin(m),
          },attrs:{"variant":!_vm.rightMessage(m) ? 'warning' : 'primary',"show":""}},[_c('p',{staticClass:"message-content break-spaces"},[_vm._v(_vm._s(m.values.find(v => v.name === 'comment').value))]),_c('div',{staticClass:"message-timestamp"},[_vm._v(_vm._s(_vm.messageCreatedAt(m)))])])}),1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('b-card',[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendMessage()}}},[_c('b-form-group',{attrs:{"label":_vm.messageTabSendMessage,"label-class":"break-spaces","description":_vm.isAdmin ? _vm.messageTabUserOnly : ''}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Message","disabled":_vm.isAdmin},model:{value:(_vm.messageInput),callback:function ($$v) {_vm.messageInput=$$v},expression:"messageInput"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isAdmin}},[_vm._v(" "+_vm._s(_vm.$t('global.send'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }