import http from '../http-common'

class MessageDataService {
  create(data) {
    return http.post('/messages', data)
  }
  getAllBySession(tokenID) {
    return http.get(`/messages/${tokenID}`)
  }
}

export default new MessageDataService()