<template>
  <b-dropdown
    id="language-list"
    variant="outline-light"
    size="sm"
    no-caret
  >
    <template #button-content>
      <span
        v-if="!getFlag(currentLang)"
        class="text-dark"
      >
        {{ currentLang.toUpperCase() }}
      </span>
      <img
        v-else
        :alt="`${currentLang.toUpperCase()}`"
        :src="getFlag(currentLang)"
        class="text-dark"
      >
    </template>

    <b-dropdown-item
      v-for="(lang, index) of selectableLang"
      :key="index"
      href="#"
      @click="changeLang(lang.tag)"
    >
      <span
        v-if="!getFlag(lang.tag)"
        class="text-dark"
      >
        {{ lang.tag.toUpperCase() }}
      </span>
      <img
        v-else
        :alt="`${lang.tag.toUpperCase()}`"
        :src="getFlag(lang.tag)"
        class="text-dark"
      >
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { LocaleDataService } from '@/services'

export default {
  data () {
    return {
      availableLang: [],
      flags: {
        en: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBklEQVR4nNWSTUsCURSG/W+zd+GPEBcqiAshF4LbaNkiWrkraEQXZRshV1JYweRoXs1KE0dTM5kJXD1xBwwOaNAHRC+8HO45Lw8XzgkE/lzBrWO+6w/AbTrNtDekeOWxXZr71qoahl9Xvf3Kgo5yUNmsBPRzOWqhEEPTpO0s2TtbCMDO6ZxK02NwZPo5nReAfM1lfNenmUphhcO82A0BGFiKm2gUO5Fgou4pXnsSsEkrwDoJQNUwvuzf/UH+0uO508NOJrEiEWaNlgDM2l3qsRj1eJyJ6nJivW3YQqGA/bRkt/wqAPqt+3q+dgutTIbx44jDC/fTOzg4d3EeRn5eAII/ucT/r3fXzvdhBUssZwAAAABJRU5ErkJggg==',
        es: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWElEQVR4nGNgGHBw3Nn5PyWYAUR87+8nC8MNOE6JC74eKvv//91isjBILwOyAcgmk23Ad6jfyDbga08P+QZcL4z7f9LD7f/1gjjyDPhPTiAeH/oJiWGgAQDnoDaa+pOcGAAAAABJRU5ErkJggg==',
        it: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQUlEQVR4nGNgGHAQN6fsPzpevP0MVnzc2RkDM4A0zDw5D45B/E+//mPFIA3f+/vheNSA/tEw6KdmGMRRkpQHHAAARBJU15WGYMEAAAAASUVORK5CYII=',
        nl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAARklEQVR4nGNgGHBw3Nn5PyWYAUR87+8nC1PPgOOUeGHx9jP/H77+RBYG6WUYBgb4VW39TwlmABHd2z+QhalngB8lXhhwAABiakZL4vEOFwAAAABJRU5ErkJggg==',
        pt: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAm0lEQVR4nGNgGHAQN6fsPzZ83NmZKMwAUjzz5DwUDBL73t9PEFPfgNmn5v2/cHfh//fP5v//93Th/9+HZv3/PmkC8Qacv7sQw4+/D84k3oCvrxeBBZEVgFxCvAGvFv0/Hx7w/0V5ORiD2P+eLCTNC59O9IE1no8I+P/5ZN//3wdnkRaI58GBOA8SiCD/kxKIMwcsHcRRkpQHHAAA56EieQgHRIMAAAAASUVORK5CYII=',
        de: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAASklEQVR4nGNgGHBg5uD7nxLMACK8U8rJwtQzwIwSLxx3dv7/vb+fLAzSyzA4DDhOAWb4eqjs//93i8nCIL0Mg8OArxTggc6KDAwA2ZUKgYeruOcAAAAASUVORK5CYII=',
        ca: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATBJREFUeNpUkU1LA0EMhpNViqWtVYrFj4OnulCoFNSbF3+Gtn9UvBSxoHgR9eRBD1Xbgyu0Ckt3MhMzMzvtGsLLSxieTBKkhxMIwczGmKJqrcUsVGJVqtQ5de8NsGbQwMTWKGaFTABqhb1X6uYVJ/295v5Z3kJIWgFZZXKpJDOmTAyUK/O7F0zvu3TYguW3BK883mUmaqxmCLXZYITj3m5j7QgKgTTP2Rac5U2kQ6U6e3zD39tO6aAB/4PNEs/G4qUC0fr7cIKfFzubpXbxdUSp6xDYwWOt/vM0wumwzd1mYbMWbMIAxjexY6gI6slVgh/n21vlOAzsYY7qNpNXSIHRXN1In8f4fR1Hx3FgEwek+72si4Rt78PaACWXU/watPyN/SH9dRfqT+69GHn5J8AAeJhkjAZrdt8AAAAASUVORK5CYII=',
        tr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nMVSQQqAMAzb33ygMvCg75j4AWWHPWHCDn5BqGxqREXEYyeyQFtyaGhDhEgOnWX4UuJolOdR9YOAlAjGYLEWvm1BZckTCMZgHUdQUZx8riqewDZN8Epdy3WN1TmEvmcKNM1zUde9OPsFuifbxGGIM5GS5UB/iXJy7BfzMBT+67vvAAAAAElFTkSuQmCC',
        'pt-BR': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXUlEQVR4nMVSS0sCURj1dxbUIiNalBGRqIFaDpZYiBPkyDjM6KIEcUYhUzBaDFaD2qYwiAI39hDRCDSN6sS9kDr42Ljwwrl83HM53/keBsPUzxy/iUlgIBenCV0EtBDsKS8WRAtiV34k8yyMkhW2pAfcde8fwYDAYS4IU3Qba8d2PD7xQD1CUS7zWI86sHLiBJsLDhfYzbJYlKzwZTxovYYRisuY2Ulh1nUGQZbRfgsjeLEPo2SBO+vXC5gVN5bCNlzeHtGMQlyG8UCFK1EFo1RpTEQIV7gPYDliw0aM6QnYFQaNZ7Fruf7RRrPzg0rjG+rDJ3zpGnXyz7+/iHAmxgjMuzNgMzXEi02UKl9odX4hpO50Ao5+AfOIEoh9l1LFlliAelOkXL40pAROE7B3rm8iqZnYHmyidbCJnG6MTphGjHF13Bi5/kU69dJMsuanIDF5I9zQRZpolad+/gAioyZCxZuybgAAAABJRU5ErkJggg==',
        fr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQUlEQVR4nGNgGHDgV7X1Pza8ePsZFHzc2RkrZgAp7t7+AQWDxD79+o+CQYq/9/ej4FED+kfDoJ+aYeBHSVIecAAAD3JJnwInPzgAAAAASUVORK5CYII=',
        zh: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfUlEQVR4nGNgGHBw3Nn5PyWYAUR87+8nC2MY8HP1VDj776W5JBowof//vwcL/n+fOAFFET6D4Ab8Wjf1/7/nC///f7f4//+Xi/7/2jSNdC/8OT77//9Xi8A0WWHwe8/M/9+nTIDQpHrhOxZJ0gOxnwrR+H3ADDhOSVIecAAAV9MrplhXfEYAAAAASUVORK5CYII=',
        cs: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzklEQVR4nGNgGHCQ0bvn/6wNJ/8v3n6GLMzgV7X1f2Tz7v+7zz/7/+nXf5Iw3ICGNU//x3Uc/F8979T/h2+/k25A9/YP/zu3vvufO+Pi//DGXf83HHtIugHdUFy36vH/6Lb9RLkGqwHdSK6JaNz9f8Pxh+QZkDPl7P/wyg3/54dl/j/u7IwTM2DzQkzTrv9NTWv+v+yd9P97fz9OjGJA59Z3//Omnv8fVbf1//6O2Xg1YhhQR4KtWA2IIsFWDAPKMib83+vugzeg8AbigAMAi7FVuEeWuBQAAAAASUVORK5CYII=',
      },
      languageListOpen: false,
    }
  },

  created () {
    this.$emit('loading', `${this.$t('loadingMessage.fetchingLocales')}...`)
    LocaleDataService.getAll()
      .then(({ data }) => {
        this.availableLang = data
      }).catch(() => {
        this.toastDanger(this.$t('errors.localesNotFound'), this.$t('errors.serverIssue'))
      }).finally(() => {
        this.$emit('loaded')
      })
  },

  computed: {
    currentLang: {
      get () {
        return this.$i18n.locale
      },
      set (l) {
        this.$i18n.locale = l
      },
    },

    selectableLang () {
      return this.availableLang.filter(l => l.tag !== this.currentLang)
    },
  },

  methods: {
    getFlag (lang) {
      return this.flags[lang]
    },

    changeLang (lang) {
      this.currentLang = lang

      document.activeElement.blur()
    },
  },
}
</script>

<style lang="scss">
#language-list {
  .dropdown-menu {
    min-width: unset;
    padding: 0.15rem 0;

    .dropdown-item {
      padding: 0.5rem 0.75rem;
    }
  }
}
</style>
