import { defineStore } from 'pinia'
import { MessageDataService } from '@/services'

export const useMessageStore = defineStore('message', {
  state: () => ({
    loading: false,
    pending: false,
    set: [],
  }),

  getters: {
    getByID (state) {
      return (ID) => state.set.find(({ id }) => ID === id)
    },
  },

  actions: {
    updateSet ({ data, force = false } = {}) {
      const set = data.map(i => {
        // Decode encoded comment
        i.values.find(m => m.name === 'comment').value = decodeURIComponent(i.values.find(m => m.name === 'comment').value)
        return Object.freeze(i)
      })

      if (force) {
        this.set = set
        return
      }

      set.forEach(newItem => {
        this.set.push(newItem)
      })
    },

    async load ({ tokenID, clear = false, force = false } = {}) {
      if (clear) {
        this.pending = false
        this.set.splice(0)
      }

      if (!force && this.set.length > 0) {
        return new Promise((resolve) => resolve(this.set))
      }

      this.loading = true
      this.pending = true
      return MessageDataService.getAllBySession(tokenID)
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.updateSet({ data, force: true })
          }

          return this.set
        }).finally(() => {
          this.loading = false
          this.pending = false
        })
    },

    async create ({ tokenID, message } = {}) {
      this.pending = true
      return MessageDataService.create({
        tokenID,
        message,
      })
        .then(({ data }) => {
          this.updateSet({ data: [data] })

          return data
        }).finally(() => {
          this.pending = false
        })
    },
  },
})
