import ca from './ca'
import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import it from './it'
import nl from './nl'
import ptBR from './pt-BR'
import pt from './pt'
import tr from './tr'
import zh from './zh'

export default {
  ca,
  de,
  en,
  es,
  fr,
  it,
  nl,
  ptBR,
  pt,
  tr,
  zh,
}
