<template>
  <b-row
    v-if="caseTokenID"
    id="case-submitted-block"
    class="mb-2"
  >
    <b-col>
      <b-card>
        <h3 class="break-spaces">{{ caseSubmittedLabel }}</h3>
        <div class="case-description mb-2 break-spaces">{{ caseSubmittedDescription }}</div>
        <b-form-group
          :label="welcomeFollowCaseTokenLabel"
          label-class="break-spaces"
        >
          <b-input-group>
            <b-form-input
              v-model="caseTokenID"
              type="text"
              disabled
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="copyTokenToClipboard()"
              >
                <font-awesome-icon
                  v-if="!clipboardFilled"
                  :icon="['far', 'copy']"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fas', 'check']"
                />
              </b-button>
              <b-button
                variant="outline-primary"
                @click="saveTokenToFile()"
              >
                <font-awesome-icon
                  v-if="!fileDownloaded"
                  :icon="['fas', 'download']"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fas', 'check']"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { useConfigurationStore } from '@/stores'
import { mapStores } from 'pinia'
import {
  customCaseTabCaseSubmittedDescription,
  customCaseTabCaseSubmittedLabel,
  customWelcomeFollowCaseTokenLabel,
} from '@/lib/defaults'

export default {
  name: 'CaseSubmitted',

  props: {
    caseTokenID: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      clipboardFilled: false,
      fileDownloaded: false,
    }
  },

  computed: {
    ...mapStores(useConfigurationStore),

    appName () {
      const appName = this.configurationStore.getByKey('APP_NAME')
      return (appName && appName.value) || 'Whistleblowing'
    },
    
    caseSubmittedLabel () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_TAB_CASE_SUBMITTED_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_TAB_CASE_SUBMITTED_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseTabCaseSubmittedLabel[lng] || customCaseTabCaseSubmittedLabel['default']
    },

    caseSubmittedDescription () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_TAB_CASE_SUBMITTED_DESCRIPTION', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_TAB_CASE_SUBMITTED_DESCRIPTION' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseTabCaseSubmittedDescription[lng] || customCaseTabCaseSubmittedDescription['default']
    },

    welcomeFollowCaseTokenLabel () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_FOLLOW_CASE_TOKEN_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_FOLLOW_CASE_TOKEN_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeFollowCaseTokenLabel[lng] || customWelcomeFollowCaseTokenLabel['default']
    },
  },

  methods: {
    copyTokenToClipboard () {
      // Block if feedback is still displayed
      if (this.clipboardFilled) return

      navigator.clipboard.writeText(this.caseTokenID)
        .then(() => {
          this.clipboardFilled = true
          setTimeout(() => {
            this.clipboardFilled = false
          }, 2000)
        }).catch(() => {
          this.toastDanger(this.$t('errors.copyToClipboardFailed'), this.$t('errors.navigatorIssue'))
        })
    },

    saveTokenToFile () {
      // Block if feedback is still displayed
      if (this.fileDownloaded) return

      const a = document.createElement('a')
      a.download = `${this.appName}.txt`

      const blob = new Blob([this.caseTokenID], { type: 'text/plain' })
      a.href = window.URL.createObjectURL(blob)
      a.click()

      this.fileDownloaded = true
      setTimeout(() => {
        this.fileDownloaded = false
      }, 2000)
    },
  }
}
</script>
