import http from '../http-common'

class CaseDataService {
  create(data) {
    return http.post('/cases', data)
  }
  get(hash) {
    return http.get(`/cases/${hash}`)
  }
}

export default new CaseDataService()