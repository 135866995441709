import Vue from 'vue'
import VueI18n from 'vue-i18n'
import './themes'
import './mixins'
import './faIcons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { createPinia, PiniaVuePlugin } from 'pinia'

import App from './App.vue'
import Axios from 'axios'

import messages from './locales'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: navigator.language.split('-')[0] || 'en',
  fallbackLocale: 'en',
  messages,
})

Vue.use(BootstrapVue, {
  BToast: {
    // see https://bootstrap-vue.org/docs/components/toast#comp-ref-b-toast-props
    autoHideDelay: 7000,
    toaster: 'b-toaster-bottom-right',
  },
})
Vue.use(IconsPlugin)
Vue.use(PiniaVuePlugin)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$http = Axios;

Vue.config.productionTip = false

const pinia = createPinia()
new Vue({
  render: h => h(App),
  pinia,
  i18n,
}).$mount('#app')