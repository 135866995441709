<template>
  <b-card no-body>
    <b-row
      align-v="center"
      class="text-center"
    >
      <b-col
        cols="12"
        md="2"
      >
        <h4 class="break-spaces">{{ caseDetailLabel }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="pt-3"
      >
        <b-form-group
          :label="caseDetailIdLabel"
          label-class="break-spaces"
          label-align="center"
          class="text-center"
        >
          {{ caseID }}
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        md="3"
        class="pt-3"
      >
        <b-form-group
          :label="caseDetailStatusLabel"
          label-class="break-spaces"
          label-align="center"
          class="text-center"
        >
          <b-badge :variant="caseStatusColor">{{ caseStatus }}</b-badge>
        </b-form-group>
      </b-col>
      <b-col
        cols="8"
        md="3"
        class="pt-3"
      >
        <b-form-group
          :label="caseDetailCreateAtLabel"
          label-class="break-spaces"
          label-align="center"
          class="text-center"
        >
          {{ caseCreationDate }}
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { useCaseStore, useConfigurationStore } from '@/stores'
import { mapStores } from 'pinia'
import {
  customCaseDetailCreateAtLabel,
  customCaseDetailIdLabel,
  customCaseDetailLabel,
  customCaseDetailStatusLabel,
} from '@/lib/defaults'

export default {
  name: 'CaseDetail',

  computed: {
    ...mapStores(useCaseStore, useConfigurationStore),

    caseDetailCreateAtLabel() {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_DETAIL_CREATE_AT_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_DETAIL_CREATE_AT_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseDetailCreateAtLabel[lng] || customCaseDetailCreateAtLabel['default']
    },

    caseDetailIdLabel() {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_DETAIL_ID_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_DETAIL_ID_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseDetailIdLabel[lng] || customCaseDetailIdLabel['default']
    },

    caseDetailLabel() {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_DETAIL_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_DETAIL_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseDetailLabel[lng] || customCaseDetailLabel['default']
    },

    caseDetailStatusLabel() {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_DETAIL_STATUS_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_DETAIL_STATUS_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseDetailStatusLabel[lng] || customCaseDetailStatusLabel['default']
    },

    caseID () {
      return this.caseStore.current.recordID
    },

    caseStatus () {
      const status = this.caseStore.current.values.find(({ name }) => name === 'case_status')?.value || ''
      switch (status) {
        case 'To be processed':
          return this.$t('case.caseDetail.status.toBeProcessed')
        case 'Under review':
          return this.$t('case.caseDetail.status.underReview')
        case 'Under Investigation':
          return this.$t('case.caseDetail.status.underInvestigation')
        case 'Managed':
          return this.$t('case.caseDetail.status.managed')
        case 'Pending approval':
          return this.$t('case.caseDetail.status.pendingApproval')
        case 'Investigation done':
          return this.$t('case.caseDetail.status.investigationDone')
        case 'Investigation requested':
          return this.$t('case.caseDetail.status.investigationRequested')
        default:
          return this.$t('case.caseDetail.status.unknown')
      }
    },

    caseStatusColor () {
      const status = this.caseStore.current.values.find(({ name }) => name === 'case_status')?.value || ''
      switch (status) {
        case 'To be processed':
          return 'primary'
        case 'Under review':
          return 'warning'
        case 'Under Investigation':
          return 'warning'
        case 'Managed':
          return 'success'
        case 'Pending approval':
          return 'info'
        case 'Investigation done':
          return 'success'
        case 'Investigation requested':
          return 'info'
        default:
          return 'dark'
      }
    },

    caseCreationDate () {
      return new Date(this.caseStore.current.createdAt).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute:'2-digit',
      })
    },
  },
}
</script>
